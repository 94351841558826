<template>
  <nav class="pagination-content shadow-md" role="navigation">
    <ul class="pagination-list">
      <li class="pagination_info">
        Página
      </li>
      <li class="pagination_info">
        <div class="select is-small">
          <select
            v-model="pageSelect"
            @change="goToPage(pageSelect)"
          >
            <option
              v-for="(current, index) in pages"
              :key="index"
              :value="current"
            >
              {{ current }}
            </option>
          </select>
        </div>
      </li>
      <li class="pagination_info">
        {{ page }} de {{ pages }}
      </li>
      <li>
        <a
          class="pagination-link"
          :disabled="currentPage <= startPage || loading"
          @click="toPreviousPage"
        >
          <span class="material-icons">
            navigate_before
          </span>
        </a>
      </li>
      <li>
        <a
          class="pagination-link"
          :disabled="currentPage >= endPage || loading" @click="toNextPage"
        >
          <span class="material-icons">
            navigate_next
          </span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import range from 'lodash/range'

export default {
  name: 'BasePagination',
  props: {
    page: {
      type: Number,
      required: true,
      default: 1
    },
    pages: {
      type: Number,
      required: true,
      default: 0
    },
    action: {
      type: Function,
      required: true
    },
    displayPages: {
      type: Number,
      default: 5
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      emptyArray: 0,
      pageSelect: this.page,
      currentPage: this.page,
      startPage: 1,
      secondPage: 2
    }
  },
  computed: {
    endPage () { return this.pages },
    secondLastPage () { return this.pages - 1 },
    normalizePagesForArray () { return this.pages + 1 },
    normalizeDisplayPages () { return this.displayPages + 1 },
    hiddenGoToFirst () { return this.currentPage >= 5 },
    hiddenToLastPage () { return this.currentPage <= (this.endPage - 5) },
    pagesArray () {
      const {
        page,
        pages,
        displayPages,
        normalizeDisplayPages,
        startPage,
        normalizePagesForArray
      } = this

      let startRange = this.startPage
      let endRange = this.normalizePagesForArray

      if (pages === startPage) return [1]
      if (pages === startRange) return [startRange]
      if (pages <= displayPages) return range(startRange, endRange)

      if (page >= (normalizePagesForArray - this.displayPages)) {
        startRange = normalizePagesForArray - this.displayPages
        endRange = this.normalizePagesForArray
      } else if (page >= displayPages) {
        startRange = this.isOdd(displayPages) ? (page + 1) - (normalizeDisplayPages / 2) : page - (displayPages / 2)
        endRange = this.isOdd(displayPages) ? page + (normalizeDisplayPages / 2) : page + (displayPages / 2)
      } else {
        endRange = normalizeDisplayPages
      }

      return range(startRange, endRange)
    }
  },
  methods: {
    isOdd (number) { return (number % 2) === 1 },
    toFirstPage () {
      this.goToPage(this.startPage)
    },
    toPreviousPage () {
      this.goToPage(this.currentPage - 1)
    },
    toNextPage () {
      this.goToPage(this.currentPage + 1)
    },
    toLastPage () {
      this.goToPage(this.endPage)
    },
    goToPage (page) {
      if (page !== this.currentPage && !this.loading && page >= this.startPage && page <= this.endPage) {
        this.action(page)
        this.currentPage = page
      }
    },
    pageToIndexArrayValue: (value) => value - 1
  }
}
</script>

<style scoped>
.pagination-content {
  display: flex;
  justify-content: flex-end;
}

.pagination-link.is-current {
  background-color: #0e2b45;
  color: white;
  font-weight: bold;
}

.pagination-link {
  background: transparent;
  color: #0e2b45;
  text-align: center;
  padding: 0.25em 0.5em;
  display: inline-block;
  text-decoration: none !important;
  margin: 0;
  font-size: 0.90rem;
  display: flex;
}

.pagination-list {
  flex-grow: inherit;
  display: flex;
  flex-direction: row;
}

ul li a {
  border: solid 1px transparent;
  border-radius: 0;
}

ul > li.active {
  background-color: #0e2b45;
  color: white;
  font-weight: bold;
}

ul > li[disabled] {
  border: solid 1px transparent;
  cursor: not-allowed;
  background-color: #e6e6e6;
}
.pagination-link[disabled] {
  border: solid 1px transparent;
}

.pagination_info {
  font-size: 15px;
  line-height: 1.54;
  letter-spacing: 0.13px;
  color: #5d5d5d;
  margin: auto 1rem;
}

.pagination-previous:hover,
.pagination-next:hover,
.pagination-link:hover {
  border: solid 1px transparent;
  color: #00a337;
}

.select select:not([multiple]) {
  padding-right: 3em;
}
.select:not(.is-multiple):not(.is-loading)::after {
  border-color: #00add8;
}

@media screen and (max-width: 768px) {
  .pagination-content {
    flex-direction: column;
    float: none;
  }
}
</style>
